<template>
  <section>
    <NavBody />
    <div class="lg:px-20 px-5 py-8 mt-10">
      <div class="border rounded p-8">
        <div class="lg:flex items-center justify-between md:flex-row flex-col lg:gap-x-60 gap-8">
          <div class="self-start">
            <p class="text-brand-black font-albertSemiBold">Password</p>
            <p class="font-albert text-sm text-brand-black/70">Reset your password.</p>
          </div>
          <form @submit.prevent="reset" class="grow space-y-5 my-5 lg:my-0">
            <Input
              label="Current Password"
              labelFor="current_password"
              id="current_password"
              type="password"
              placeholder="********"
              v-model="v$.current_password.$model"
            />
            <div class="text-sm whitespace-nowrap text-red-500" v-for="(error, index) in v$.current_password.$errors" :key="index">
              {{ error.$message }}
            </div>
            <Input
              :isRequired="true"
              label="New Password"
              labelFor="new_password"
              id="new_password"
              type="password"
              placeholder="********"
              v-model="v$.new_password.$model"
            />
            <ul class="list-disc text-sm whitespace-nowrap text-red-500">
              <li v-for="error in v$.new_password.$errors" :key="error.$message">{{ error.$message }}</li>
            </ul>
            <Input
              :isRequired="true"
              label="Confirm Password"
              labelFor="confirm_password"
              id="confirm_password"
              type="password"
              placeholder="********"
              v-model="v$.new_password_confirmation.$model"
            />
            <ul class="list-disc text-sm whitespace-nowrap text-red-500 mb-4">
              <li v-for="error in v$.new_password_confirmation.$errors" :key="error.$message">{{ error.$message }}</li>
            </ul>
            <Button label="Save Changes" color="primary" :disabled="checkDisability" :loading="loading" type="submit" full />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import NavBody from '@/components/NavBody.vue';
import Input from '@/components/form/Input.vue';
import Button from '@/components/Button.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const router = useRouter();
const store = useStore();
const loading = ref(false);
const disabled = ref(true);
const details = ref({
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
});

const passwordIsSame = (password) => {
  return password === details.value.new_password;
};

const rules = {
  current_password: {
    required,
  },
  new_password: {
    required,
    upperCase: helpers.withMessage(
      () => `Include at least one uppercase letter`,
      (value) => /(?=.*[A-Z])/.test(value),
    ),
    minLength: minLength(8),
    lowerCase: helpers.withMessage(
      () => `Include at least one lowercase letter`,
      (value) => /(?=.*[a-z])/.test(value),
    ),
    number: helpers.withMessage(
      () => `Include at least one number`,
      (value) => /(?=.*?[0-9])/.test(value),
    ),
    specialCharacter: helpers.withMessage(
      () => `Include at least a special character`,
      (value) => /(?=.*?[#?!@$%^&*-])/.test(value),
    ),
  },
  new_password_confirmation: { required, sameAsPassword: helpers.withMessage('Password does not match', passwordIsSame) },
};

const checkDisability = computed(() => {
  return details.value.current_password && details.value.new_password && details.value.new_password_confirmation !== ''
    ? (disabled.value = false)
    : (disabled.value = true);
});

const reset = async () => {
  try {
    if (!v$.value.$anyDirty) return;
    loading.value = true;
    const { data } = await store.dispatch('auth/resetPassword', details.value);
    alert(data.message, 'success');
    loading.value = false;
    store.dispatch('logout');
    router.push('/jobs');
  } catch (error) {
    loading.value = true;
    if (error.response && error.response.status === 422) {
      console.log(error.response.data.errors);
    }
    alert(errorMessage(error), 'error');
    loading.value = false;
  }
};
const v$ = useVuelidate(rules, details);
</script>
