<template>
  <main>
    <div v-if="pageLoaded">
      <div class="border rounded p-8">
        <div class="flex items-center justify-between md:flex-row flex-col lg:gap-44 gap-10 mb-8">
          <div class="self-start">
            <p class="text-brand-black font-albertSemiBold">Preferences</p>
            <p class="font-albert text-sm text-brand-black/70">We will show results based on your preferences</p>
          </div>
          <div class="grow lg:w-1/2">
            <div class="grid mb-6">
              <form>
                <div class="mb-6">
                  <Select
                    id="role"
                    label="What point are you in your job search?"
                    :options="enums.candidate_availability_statuses"
                    v-model="details.availability_status"
                  />
                </div>
                <div class="mb-6">
                  <label for="role" class="block text-lg font-albert text-brand-black/70 mb-3">What type of role are you looking for?</label>
                  <div class="grid gap-4 md:grid-cols-4 sm:grid-cols-2">
                    <div class="inline-flex gro mr-2" v-for="item in enums.job_types" :key="item.value">
                      <input
                        :id="item.value"
                        type="checkbox"
                        :value="item.key"
                        v-model="details.job_types"
                        class="w-6 h-6 text-brand-primary mb-2 bg-brand-light/10 rounded-[10px] p-3 appearance-none focus:outline-none border border-brand-light focus:border-brand-primary focus:border focus:ring-brand-primary"
                      />
                      <label :for="item.value" class="ml-2 text-brand-black/70 text-lg whitespace-nowrap">{{ item.value }}</label>
                    </div>
                  </div>
                </div>
                <div class="mb-6">
                  <Select
                    id="type"
                    label="What type of company are you looking to work for?"
                    :options="companyCategory"
                    v-model="details.company_category"
                  />
                </div>
                <div class="mb-6">
                  <label for="qualification" class="block text-lg font-medium text-brand-black/70">Where would you like to work in?</label>
                  <div class="grid gap-4 md:grid-cols-2">
                    <Select id="Country" :options="country" v-model="location.country" />
                    <Select id="State" :options="state" v-model="location.state" />
                  </div>
                </div>
                <div class="mb-6">
                  <div class="mb-3">
                    <label for="" class="text-lg text-gray-500">What are you looking for in your next role</label>
                  </div>
                  <div class="lg:flex flex-wrap mb-5">
                    <template v-for="(preference, index) in perks_options" :key="index">
                      <input
                        type="checkbox"
                        :id="'_' + index"
                        :value="preference"
                        class="hidden peer perk-input"
                        required=""
                        v-model="selectedPerks"
                      />
                      <label :for="'_' + index" class="perk p-3 mr-2 mb-2">
                        {{ preference }}
                      </label>
                    </template>
                  </div>
                </div>

                <div class="mb-6">
                  <label for="Currency" class="block text-lg font-medium text-brand-black/70 mb-3">Salary currency</label>
                  <Select id="Currency" :options="enums.currencies" v-model="details.desired_salary_currency" />
                </div>

                <div class="mb-10">
                  <label for="salary" class="block text-lg font-medium text-brand-black/70 mb-3">Desired salary</label>
                  <input
                    id="salary"
                    type="text"
                    v-model="details.desired_salary"
                    class="bg-brand-light/10 text-base rounded-[10px] w-full p-4 appearance-none focus:outline-none border border-brand-light focus:border-brand-dark focus:border focus:ring-1"
                    placeholder="Enter amount"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Button label="Save Changes" color="primary" :loading="loading" @click="updateProfile" class="mt-8 float-right" />
    </div>
    <Loading :is-loading="!pageLoaded" className="flex justify-center py-6" />
  </main>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { errorMessage } from '@/utils/helper';

import Input from '@/components/form/Input.vue';
import Select from '@/components/form/Select.vue';
import TextArea from '@/components/form/TextArea.vue';
import Button from '@/components/Button.vue';
import Loading from '@/components/Loading.vue';

const store = useStore();
const route = useRoute();
const toast = useToast();
const state = ref(null);

const enums = ref(null);
const companyCategory = ref(null);
const pageLoaded = ref(false);
const loading = ref(false);
const details = ref(null);
const location = ref({
  country: '',
  state: '',
});
const selectedPerks = ref([]);

const perks_options = ref([
  'More Money',
  'Flexibility and Wellbeing',
  'Working with great people',
  'Innovative product',
  'Remote Work',
  'Great tech and tools',
  'Recognition and reward',
  'Development and progression',
  'Diversity and Inclusion',
]);

const updateProfile = async () => {
  try {
    const perks = selectedPerks.value;
    if (!perks.length) {
      return toast.error('You have not selected your preferred perks', {
        timeout: 3000,
        hideProgressBar: true,
      });
    }

    const data = {
      ...details.value,
      next_role_perks: perks,
      location: location.value,
    };

    loading.value = true;
    const res = await store.dispatch('auth/updateUserPreference', data);

    toast.success(res.message, {
      timeout: 3000,
      hideProgressBar: true,
    });

    const user = await store.dispatch('auth/getUser', { forceReload: true });
    details.value = {
      availability_status: user.candidate?.job_preference?.availability_status,
      job_types: user.candidate?.job_preference?.job_types,
      company_category: user.candidate?.job_preference?.company_category_uuid,
      location_country: user.candidate?.job_preference?.location_country,
      location_state: user.candidate?.job_preference?.location_state,
      next_role_perks: user.candidate?.job_preference?.next_role_perks,
      desired_salary: user.candidate?.job_preference?.desired_salary,
      desired_salary_currency: user.candidate?.job_preference?.desired_salary_currency,
    };
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.log(error.response.data.errors);
    }
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};

const country = computed(() => {
  return store.state.global.Countries;
});

watch(
  () => location.value.country,
  async (country) => {
    try {
      state.value = await store.dispatch('global/getStates', country);
    } catch (error) {
      toast.error(errorMessage(error), {
        timeout: 3000,
        hideProgressBar: true,
      });
    }
  },
);

onMounted(async () => {
  getSetupData();
});

const getSetupData = async () => {
  try {
    enums.value = await store.dispatch('global/getEnums');
    companyCategory.value = await store.dispatch('global/getCompanyCategory');
    const user = await store.dispatch('auth/getUser', { forceReload: false });

    details.value = {
      availability_status: user.candidate?.job_preference?.availability_status,
      job_types: user.candidate?.job_preference?.job_types || [],
      company_category: user.candidate?.job_preference?.company_category_uuid,
      desired_salary: user.candidate?.job_preference?.desired_salary,
      desired_salary_currency: user.candidate?.job_preference?.desired_salary_currency,
      location_country: user.candidate?.job_preference?.location_country,
      location_state: user.candidate?.job_preference?.location_state,
    };

    selectedPerks.value = user.candidate?.job_preference?.next_role_perks || [];

    location.value = {
      country: user.candidate?.job_preference?.location_country,
      state: user.candidate?.job_preference?.location_state,
    };
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    pageLoaded.value = true;
  }
};
</script>

<style scoped></style>
